<template>
	<div>
		<meta http-equiv="Content-Type" content="text/html; charset=euc-kr" />
		<meta http-equiv="Cache-Control" content="no-cache" />
		<meta http-equiv="Expires" content="0" />
		<meta http-equiv="Pragma" content="no-cache" />
		<form
			@submit.prevent="submitForm"
			ref="payform"
			name="payform"
			hidden="true"
			v-bind:action="getActionUrl()"
			method="post"
			accept-charset="euc-kr"
		>
			<input type="text" v-model="payRequest.payMethod" name="PAYMETHOD" />
			<input type="text" v-model="payRequest.type" name="TYPE" />
			<input type="text" v-model="payRequest.cpid" name="CPID" />
			<input type="text" v-model="payRequest.orderNo" name="ORDERNO" />
			<input type="text" v-model="payRequest.productType" name="PRODUCTTYPE" />
			<input type="text" v-model="payRequest.amount" name="AMOUNT" />
			<input type="text" v-model="payRequest.productName" name="PRODUCTNAME" />
			<input type="text" v-model="payRequest.productCode" name="PRODUCTCODE" />
			<input type="text" v-model="payRequest.userId" name="USERID" />
			<input type="text" v-model="payRequest.taxFreeCd" name="TAXFREECD" />
			<input type="text" v-model="payRequest.telNo2" name="TELNO2" />

			<input type="text" v-model="payRequest.quotaopt" name="QUOTAOPT" />
			<input type="text" v-model="payRequest.engFlag" name="eng_flag" />
			<input type="text" v-model="payRequest.cardList" name="CARDLIST" />
			<input type="text" v-model="payRequest.email" name="EMAIL" />
			<input type="text" v-model="payRequest.userName" name="USERNAME" />
			<input type="text" v-model="payRequest.reservedIndex1" name="RESERVEDINDEX1" />
			<input type="text" v-model="payRequest.reservedIndex2" name="RESERVEDINDEX2" />
			<input type="text" v-model="payRequest.reservedString" name="RESERVEDSTRING" />
			<input type="text" v-model="payRequest.homeUrl" name="HOMEURL" />
			<input type="text" v-model="payRequest.failUrl" name="FAILURL" />
			<input type="text" v-model="payRequest.closeUrl" name="CLOSEURL" />
			<input type="text" v-model="payRequest.appUrl" name="APPURL" />
			<input type="text" v-model="payRequest.directResultFlag" name="DIRECTRESULTFLAG" />
			<input type="text" v-model="payRequest.setLogo" name="SET_LOGO" />
			<input type="submit" />
		</form>
	</div>
</template>

<script>
export default {
	data() {
		var payMethodInfo = JSON.parse(localStorage.getItem('payMethodInfo'));

		return {
			payRequest: {
				actionUrl: '',

				// 필수값
				payMethod: 'CARDK', // 해외결제 가능
				type: this.$utils.isMobileDevice() ? 'M' : 'P', //P:PC, M:모바일
				cpid: this.getCpId(payMethodInfo.serviceType), // 가맹점 ID
				orderNo: this.getOrderNo(payMethodInfo.serviceType, payMethodInfo.orderId),
				productType: 1, // 상품구분(1:디지털, 2:실물)
				amount: payMethodInfo.amount, //결제금액
				//amount: 1000, // 결제금액
				productName: payMethodInfo.orderSummary, // 상품명
				productCode: '', //상품코드
				userId: '', //userid
				taxFreeCd: '00', //과세여부(00:과세,01:비과세,02:복합과세)
				telNo2: '', //비과세 대상금액(taxFreeCd가 02인 경우에만 전송)
				// 선택값
				quotaopt: '', // 최대 할부 개월 수
				engFlag: 'N', // 결제창 영문 사용여부
				cardList:
					'CCLG:CCBC:CCDI:CCLO:CCKE:CCSS:CCKM:CCWR:BC81:CCHN:CCNH:CCCT:CCCU:CCKJ:CCSU:CCJB:CCCJ:CCKD:CCSB:CCPB:CCSM:CCXA:CCXB:CCXC:CCUF:CCHS:CCKA:CCKK:CCMD:CCTO:CCPH',
				hideCardList: '', // 결제 창 카드사 숨김 값
				skipYn: '', // cardList에 카드사 코드만 하나만 입력하여 바로 이동
				email: '',
				userName: payMethodInfo.corporateName, // 상호명
				reservedIndex1: '', // 예약항목1
				reservedIndex2: '', // 예약항목2
				reservedString: '', // 예약스트링
				homeUrl: process.env.VUE_APP_FRONT_SERVER + '/pay?success=true&close=true', // 결제 성공 후 이동할 URL
				failUrl: process.env.VUE_APP_FRONT_SERVER + '/pay?fail=true&close=true', // 결제실패 후 확인버튼 입력시 이동할 URL
				closeUrl: process.env.VUE_APP_FRONT_SERVER + '/pay?close=true', // 결제창에서 취소 누를 시 이동할 URL
				appUrl: process.env.VUE_APP_FRONT_SERVER + '/pay?success=true&close=true', // 인증 완료 후 돌아갈 앱의 URL
				directResultFlag: 'Y', // 키움페이 결제 완료 창 없이 homeurl로 이동 여부 Y,N
				setLogo: '', // 결제 창 하단 상점로고
			},
		};
	},

	mounted() {
		this.submitForm();
	},

	methods: {
		submitForm() {
			this.$refs.payform.submit();
		},
		getActionUrl: function () {
			return window.__env.code.CARD_K_URL;
		},
		getOrderNo(serviceType, orderId) {
			return 'DAOU_SAAS_' + orderId;
		},
		getCpId(serviceType) {
			if (serviceType === 'VPC') {
				return window.__env.code.VPC_CP_K_ID;
			} else {
				return window.__env.code.SAAS_CP_K_ID;
			}
		},
	},
};
</script>

<style></style>
